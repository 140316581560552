<template>
  <v-container fluid>
    <v-card flat>
      <v-toolbar color="white" dark extended flat>
        <!--<v-app-bar-nav-icon></v-app-bar-nav-icon>-->
      </v-toolbar>

      <v-card class="mx-auto" max-width="1200" style="margin-top: -64px;">
        <v-toolbar flat>
          <v-toolbar-title class="black--text">Huella hídrica resultados</v-toolbar-title>

          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar.."
            single-line
            hide-details
            class="mr-2"
          ></v-text-field>

           <download-excel
            class = "mr-1 white--text d-print-none v-btn v-btn--contained theme--light v-size--default black"
            :data = "results"
            :fields = "headers_export"
            worksheet = "Resultados"
            name = "resultsFootPrint.xls">
                <v-icon left style="color:white;">mdi-microsoft-excel</v-icon> Descargar en Excel  
            </download-excel>
            <v-btn color="black" dark class="d-none d-sm-flex" @click="modalSendSms = true"> <v-icon left>mdi-forum</v-icon> Enviar SMS masivo </v-btn>
            <v-menu bottom right>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" class="d-print-none">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="dialogFilter = true">
                    <v-list-item-title><v-icon left>mdi-filter</v-icon> Filtrar</v-list-item-title>
                </v-list-item>
                <v-list-item @click="modalSendSms = true">
                  <v-list-item-title><v-icon left>mdi-forum</v-icon> Enviar SMS masivo </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
        </v-toolbar>

        <v-divider></v-divider>

        <v-card-text>
          <v-data-table
            :loading="loading"
            loading-text="Cargando... Por favor espere"
            :headers="headers"
            :items="results"
            :search="search"
            :footer-props="pagination"
            class="elevation-1"
            :header-props="{ sortIcon: null }"
          >
          
            <template v-slot:item.attributes.createdAt="{ item }">
              {{ $moment(item.attributes.createdAt).format('lll') }}
            </template>

            <template v-slot:item.attributes.address="{ item }">
              {{ item.attributes.address.length >= 50 ? item.attributes.address.slice(0, 50)+'...' : item.attributes.address}}
            </template>

            <template v-slot:item.action="{ item }">
              <v-icon
                class="mr-2"
                @click="showDialogresult(item)"
              >
                mdi-eye-outline
              </v-icon>
            </template>

            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize">RECARGAR</v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-card>

    <!-- Dialogo Dialogo show info result -->
    <v-dialog
      v-model="modalResult"
      width="500"
      scrollable
      v-if="result"
    >
      <v-card>
        <v-card-title
          class="title grey lighten-2"
          primary-title
        >
          <span class="text-capitalize"> Detalle Huella Hídrica </span>
        </v-card-title>

        <v-card-text>
          <div class="mb-2"></div>
          <v-row align="center" justify="center">

            <v-col cols="12">
                <div class="body-1 text-wrap mb-3" style="color: black;" v-if="result.attributes.createdAt"> <strong><v-icon>mdi-calendar</v-icon> Fecha:</strong> {{ $moment(result.attributes.createdAt).format('lll') }} </div>
                <div class="body-1 text-wrap mb-3" style="color: black;" v-if="result.attributes.city"> <strong><v-icon>mdi-map-marker</v-icon> Municipio:</strong> {{ result.attributes.city }} </div>
                <div class="body-1 text-wrap mb-3" style="color: black;" v-if="result.attributes.foods"> <strong><v-icon>mdi-food</v-icon> Consumo comidas:</strong> {{ result.attributes.foods }} </div>
                <div class="body-1 text-wrap mb-3" style="color: black;" v-if="result.attributes.domestic"> <strong><v-icon>mdi-home</v-icon> Consumo doméstico:</strong> {{ result.attributes.domestic }} </div>
                <div class="body-1 text-wrap mb-3" style="color: black;" v-if="result.attributes.total"> <strong><v-icon>mdi-file-table-box</v-icon> Total:</strong> {{ result.attributes.total }} </div>
                <div class="body-1 text-wrap mb-3" style="color: black;" v-if="result.attributes.name"> <strong><v-icon>mdi-account</v-icon> Nombre:</strong> {{ result.attributes.name }} </div>
                <div class="body-1 text-wrap mb-3" style="color: black;" v-if="result.attributes.cellphone"> <strong><v-icon>mdi-phone</v-icon> Teléfono:</strong> {{ result.attributes.cellphone }} </div>
                <div class="body-1 text-wrap mb-3" style="color: black;" v-if="result.attributes.email"> <strong><v-icon>mdi-at</v-icon> Email:</strong> {{ result.attributes.email }} </div>
            </v-col>
           
          </v-row>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            @click="modalResult = false"
          >
           <v-icon left>mdi-close</v-icon> Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- *End* Dialogo show info result -->

     <!-- Dialogo Dialogo Send Call or SMS -->
    <v-dialog
      v-model="modalSendSms"
      width="500"
      persistent
      scrollable
    >
    <v-form
      ref="formcall"
      v-model="validCallSMS"
    >
      <v-card>
        <v-card-title
          class="title grey lighten-2"
          primary-title
        >
          <span class="text-capitalize"> Enviar SMS Masivo </span>
        </v-card-title>

        <v-card-text>
          <div class="mb-2"></div>
          <v-row align="center" justify="center">

            <v-col cols="12">
              <v-textarea
                outlined
                v-model="body"
                :rules="bodyRules"
                label="Mensaje"
                placeholder="Escriba el mensaje"
                counter="160"
              ></v-textarea>
            </v-col>
           
          </v-row>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="modalSendSms = false"
          >
           <v-icon left>mdi-close</v-icon> Cerrar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="green"
            dark
            outlined
            @click="sendCallSMS()"
            :disabled="body ? false : true"
          >
           <v-icon left>mdi-send</v-icon> Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
    <!-- *End* Dialogo Send Call or SMS -->

    <!-- Dialogo filter orders -->
    <v-dialog v-model="dialogFilter" width="700" persistent scrollable>
      <v-form ref="formFilter" v-model="validFilter">
        <v-card>
          <v-card-title class="title grey lighten-2" primary-title>
            <span class="text-capitalize">Filtrar</span>
          </v-card-title>

          <v-card-text>
            <div class="mb-2"></div>
            <v-row>
              <v-col cols="12" sm="6">
                <v-date-picker v-model="dates" range @change="checkDates()" locale="es"></v-date-picker>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field v-model="dateRangeText" label="Rango de fechas" prepend-inner-icon="mdi-calendar" class="mb-3" readonly outlined hide-details></v-text-field>
                <!--<h3
                  ref="radio"
                  class="mt-3"
                >
                  Tipo de orden
                </h3>
                <v-radio-group v-model="sourceOption" class="mt-1">
                  <v-radio
                    label="Todos"
                    value="all"
                  ></v-radio>
                  <v-radio
                    label="POS"
                    value="pos"
                  ></v-radio>
                  <v-radio
                    label="Festy Ordenes"
                    value="client"
                  ></v-radio>
                </v-radio-group>-->

                 <v-autocomplete
                    v-model="municipio"
                    :items="municipios"
                    outlined
                    chips
                    multiple
                    label="Seleccione los minicipios"
                    hint="Puede seleccionar varios municipios para filtrar"
                    persistent-hint
                ></v-autocomplete>

              </v-col>

            </v-row>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="red" outlined @click="dialogFilter = false">
              <v-icon left>mdi-close</v-icon>Cerrar
            </v-btn>
            <v-spacer></v-spacer>
            
            <v-btn color="primary" dark outlined @click="initialize()">
              <v-icon left>mdi-autorenew</v-icon>Limpiar
            </v-btn>
            <v-btn color="success" dark outlined @click="filterResult()">
              <v-icon left>mdi-filter</v-icon>Filtrar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- *End* Dialogo filter orders -->

  </v-container>
</template>

<script>
import Parse from "parse";
import JsonExcel from 'vue-json-excel'

export default {

    components: {
        'download-excel': JsonExcel
    },

  data() {
    return {
      results: [],
      result: '',
      search: "",
      loading: true,
      headers: [
        {
          text: "Municipio",
          align: "left",
          value: "attributes.city"
        },
        { text: "Consumo comidas", value: "attributes.foods" },
        { text: "Consumo doméstico", value: "attributes.domestic" },
        { text: "Nombre", value: "attributes.name" },
        { text: "Teléfono", value: "attributes.cellphone" },
        { text: "email", value: "attributes.email" },
        { text: "Total", value: "attributes.total" },
        { text: "Fecha creación", value: "attributes.createdAt" },
        { text: 'Acciones', value: 'action', sortable: false, align: "center", },
      ],
      pagination: {
        "items-per-page-options": [50, 60, 70, 100, -1]
      },
      headers_export: {
            'ID': 'id',
            'Municipio': 'attributes.city',
            'Consumo comidas': 'attributes.foods',
            'Consumo doméstico': 'attributes.address',
            'Nombre': 'attributes.name',
            'Teléfono': 'attributes.cellphone',
            'email': 'attributes.email',
            'Total': 'attributes.total',
            'Fecha creación': {
                field: 'attributes.createdAt',
                callback: (value) => {
                    let startDate = value.toLocaleString()
                    return `${startDate}`;
                }
            }
      },
      modalResult: '',
      modalSendSms: false,
      bodyRules: [
        v => !!v || 'Mensaje es requerido',
        v => !!v && v.length <= 160 || 'El mensaje no debe sobrepasar los 160 caracteres'
      ],
      body: '',
      validCallSMS: false,
      dialogFilter: false,
      validFilter: false,
      dates: [],
      sourceOption: "all",
      municipio: '',
      municipios:[  
        "Buenavista",
        "Caimito",
        "Chal\u00e1n",
        "Colos\u00f3",
        "Corozal",
        "Cove\u00f1as",
        "El Roble",
        "Galeras",
        "Guaranda",
        "La Uni\u00f3n",
        "Los Palmitos",
        "Majagual",
        "Morroa",
        "Ovejas",
        "Sampu\u00e9s",
        "San Antonio de Palmito",
        "San Benito Abad",
        "San Juan de Betulia",
        "San Marcos",
        "San Onofre",
        "San Pedro",
        "Sinc\u00e9",
        "Sincelejo",
        "Sucre",
        "Tol\u00fa",
        "Tol\u00fa Viejo"
      ]
    };
  },

  mounted() {
    this.initialize();
  },
  
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
  },

  methods: {
    async initialize() {
      try {
        this.dates = []
        let results = await Parse.Cloud.run("getFootPrintResult");
        this.results = results;
        //eslint-disable-next-line
        //console.log(this.results);
        this.loading = false;
      } catch (error) {
        this.$snotify.error(`${error}`, "Resultados", {
          timeout: 4000,
          preventDuplicates: true
        });
      }
    },

    openImageUrl(item) {
      window.open(item.attributes.rider.attributes.photoUrl, "_blank");
    },

    showDialogresult(item){
      this.result = item
      this.modalResult = true
    },

    async sendCallSMS(){

            if (this.$refs.formcall.validate()) {

                let array_phones = this.results.map(o => o.get("cellphone") ? `57${o.get("cellphone")}` : '')
                array_phones = Array.from(new Set(array_phones))
                array_phones = array_phones.filter(Boolean).sort();

                if (array_phones.length <= 0) {
                    this.$snotify.error(`Debe enviar almenos un celular` , 'SMS Masivo', {
                        timeout: 4000,
                        preventDuplicates: true
                    });
                }
                try {
                    let params = {
                        phones: array_phones,
                        message: this.body
                    };
                    //console.log(params);
                    await Parse.Cloud.run("sendSMSMasive", params);
                        
                    this.$snotify.success(`SMS enviado correctamente` , 'Envío SMS', {
                        timeout: 4000,
                        preventDuplicates: true
                    });
    
                    this.modalSendSms = false
                        
                } catch (error) {
                    this.$snotify.error(`${error}` , 'SMS Masivo', {
                        timeout: 4000,
                        preventDuplicates: true
                    });
                }
                
            }else{
                this.$snotify.error(`Compruebe todo los campos antes de enviar el formulario` , 'SMS', {
                    timeout: 4000,
                    preventDuplicates: true
                });
            }
        },

        checkDates(){
          this.dates.sort(function(a, b) {
              var c = new Date(a);
              var d = new Date(b);
              return c-d;
          });
        },

         async filterResult(){

          const params = {
            init: this.dates.length > 0 ? this.dates[0] : '',
            end: this.dates.length > 0 ? this.dates[1] : '',
            municipios: this.municipio,
          };

          try {
            let results = await Parse.Cloud.run("getFilterBO", params);
            this.results = results;
            //eslint-disable-next-line
            //console.log(params);

            this.dialogFilter = false

          } catch (error) {
            this.$snotify.error(`${error}`, "Filtrar", {
              timeout: 4000,
              preventDuplicates: true
            });
          }

        },
  }
};
</script>